const PINATA_PUBLIC_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI5YmUwMzFkNS00MmQ5LTQ2ZGQtOGQyMC1mZTY0MGNlNTU0NDAiLCJlbWFpbCI6ImxlbmRvbmJyYWNld2VsbDExMTRAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9LHsiaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImVmNzZmM2RkOTViZDkwYTRmODc5Iiwic2NvcGVkS2V5U2VjcmV0IjoiMGY1ZjExODY2MjVhNWIzNWQ2YzQ2YzdlZDViMGU2OGM3NmRlMjQ3N2JmMWRhMWQ5MzQ0YWE1ZmVkNWIyMTFjZSIsImlhdCI6MTcyMDI1NDE3Mn0.GDNZDc1JHgLj9i7kJcGd9mGVoX_Ag7HQHSvd2ZWzqPw'

export const handleSetMetaData = async (file) => {
    const imgData = new FormData();
    if (!file) return
    imgData.append("file", file);

    const imgRes = await fetch(
        "https://api.pinata.cloud/pinning/pinFileToIPFS",
        {
            method: "POST",
            headers: {
                Authorization: `Bearer ${PINATA_PUBLIC_KEY}`,
            },
            body: imgData,
        }
    );

    const imgJsonData = await imgRes.json();

    return imgJsonData.IpfsHash;

}

