import React, { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ImageUploading from 'react-images-uploading';
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { TagData } from "../../context/AuthData";
import { IoIosAddCircle } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { SelectValue } from "../../context/AuthData";
import UseContext from "../../context/useContext";
import { handleSetMetaData } from "./commonFunc";
import Loading from "../loding/Loding";

export default function Submit() {
  const { data, sumbitDrop } = useContext(UseContext);
  const { personURL, setPersonURL, person, setPerson, images, setImages, gallery, setGallery, tags, setTags, schedule, setSchedule, dropdown, setDropdown, chain, setChain, loading, setLoading } = data
  const navigate = useNavigate();
  const goToPage = (url) => {
    navigate(url);
  }
  const menuDropdown = useRef(null);
  const maxNumber = 69;

  const selectTag = (item) => {
    if (tags.includes(item)) {
      setTags(tags.filter((_item) => _item !== item));
    } else {
      setTags([...tags, item]);
    }
  }

  const changeTitle = (e, title) => {
    setPerson((prevState) => ({ ...prevState, [title]: e }));
  }

  const changeURL = (e, title) => {
    setPersonURL((prevURLState) => ({ ...prevURLState, [title]: e }));
  }

  const changeChain = (e) => {
    setChain(e)
  }

  const onChangeImage = (imageList) => {
    setImages(imageList);
  };

  const onChangeGallery = (imageList) => {
    setGallery(imageList);
  };

  const submitData = async () => {
    setLoading(true);
    if (person.title === "" || person.title === undefined || person.description === "" || person.description === undefined || personURL.project === "" || personURL.project === undefined || images[0] === undefined || images[0].data_url === "") {
      alert("sorry")
      setLoading(false);
    } else {
      if (!images[0]) {
        setLoading(false);
        return;
      }
      try {
        const pinataPublicURL = "https://ipfs.io/ipfs/";
        const imgjson = await handleSetMetaData(images[0].file);
        const gallaryjson = await handleSetMetaData(gallery[0].file);
        // Axios request data for submitting 
        let dropData = {
          title: person.title,
          url: personURL.project,
          coverimageAlt: person.imgTag,
          shortDescription: person.shortDescription,
          description: person.description,
          startData: person.date,
          startTime: person.time,
          tokenSupply: person.tokenNmber,
          blockchain: chain,
          preMintPrice: person.preMintPrice,
          publicMin: person.pubMintPrice,
          mintLimit: person.mintLimit,
          mintURL: person.mint,
          scanURL: personURL.scan,
          gallary: pinataPublicURL + gallaryjson,
          website: personURL.webSite,
          discord: personURL.discord,
          telegram: personURL.telegram,
          imageUrl: pinataPublicURL + imgjson,
          tags: tags
        }
        sumbitDrop(dropData)
        setLoading(false)
        setPersonURL({})
        setPerson({})
        setImages([])
        setGallery([])
        setTags([])
        setChain("Select")
        goToPage("/")
      } catch (err) {
        setLoading(false)

      }
    }
  }

  useEffect(() => {
    setPerson((prevState) => ({ ...prevState, "date": "", "time": "" }));
  }, [schedule])

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuDropdown.current && !menuDropdown.current.contains(event.target)) {
        setDropdown(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuDropdown]);

  return (
    <div className="w-full max-w-[1440px] flex flex-col justify-center items-center bg-white mx-auto pt-6 pb-12 xs:pt-12 px-4 xs:px-14" >
      <div className="w-full h-full flex flex-col gap-5 items-center justify-center">
        <h1 className="w-full text-start font-bold text-[20px] xs:text-[24px]">General</h1>
        <div className="w-full h-full flex flex-col xs:flex-row justify-center xs:justify-between items-center gap-5">
          <div className="w-full h-full flex flex-col text-start gap-2">
            <div className="font-bold text-[16px] xs:text-[20px]">Title <span className="text-red-700">*</span></div>
            <input onChange={(e) => changeTitle(e.target.value, "title")} className="flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold" value={person.title} placeholder="e.g Name of Project" />
          </div>
          <div className="w-full h-full flex flex-col text-start gap-2">
            <div className="font-bold text-[16px] xs:text-[20px]">URL <span className="text-red-700">*</span></div>
            <input type="url" onChange={(e) => changeURL(e.target.value, "project")} className="flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold" value={personURL.project} placeholder="https://" />
          </div>
        </div>
        <div className="w-full h-full flex flex-col xs:flex-row justify-center xs:justify-between items-center gap-5">
          <div className="w-full h-full flex flex-col text-start gap-2">
            <div className="font-bold text-[16px] xs:text-[20px]">Cover Image<span className="text-red-700">*</span></div>
            <ImageUploading
              value={images}
              onChange={onChangeImage}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper relative mx-auto flex flex-col w-[280px]">
                  <button
                    style={isDragging ? { color: 'red' } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <div className="w-[280px] h-[200px] rounded-lg flex flex-col justify-center items-center bg-color-100 text-color-200 text-5xl">
                      <IoIosAddCircle />
                    </div>
                  </button>
                  &nbsp;
                  <button className="flex flex-col text-2xl absolute top-3 text-white left-[245px] z-20" onClick={onImageRemoveAll}>
                    <MdDelete />
                  </button>
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item absolute z-10 top-0">
                      <img src={image['data_url']} alt="input" className="w-[280px] h-[200px] rounded-lg" type="image/webp" />
                      <div className="image-item__btn-wrapper">
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
          </div>
          <div className="w-full h-full flex flex-col text-start gap-2">
            <input onChange={(e) => changeTitle(e.target.value, "imgTag")} className="flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold" value={person.imgTag} placeholder="Cover image alt tag" />
          </div>
        </div>
        <div className="w-full h-full flex flex-col xs:flex-row justify-center xs:justify-between items-center gap-5">
          <div className="w-full h-full flex flex-col text-start gap-2">
            <div className="font-bold text-[16px] xs:text-[20px]">Short Description</div>
            <textarea
              className="border-[#dddddd] border-[0.5px] mx-auto bg-white rounded-xl w-full p-4 md:w-2/3 lg:w-full h-[150px] xl:h-[280px] placeholder:italic placeholder:text-slate-400 block  shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500/80 focus:ring-1 resize-none  shadow-cyan-500/50"
              type="text"
              name="discription"
              placeholder="Write short description."
              rows={4}
              cols={40}
              value={person.shortDescription}
              onChange={(e) => changeTitle(e.target.value, "shortDescription")}
            >
            </textarea>
          </div>

          <div className="w-full h-full flex flex-col text-start gap-2">
            <div className="font-bold text-[16px] xs:text-[20px]">Description <span className="text-red-700">*</span></div>
            <textarea
              className="border-[#dddddd] border-[0.5px] mx-auto bg-white rounded-xl w-full p-4 md:w-2/3 lg:w-full h-[150px] xl:h-[280px] placeholder:italic placeholder:text-slate-400 block  shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500/80 focus:ring-1 resize-none  shadow-cyan-500/50"
              type="text"
              name="discription"
              placeholder="Write something about yourself."
              rows={4}
              cols={40}
              value={person.description}
              onChange={(e) => changeTitle(e.target.value, "description")}
            >
            </textarea>
          </div>
        </div>
        <div className="w-full h-full flex flex-col items-center gap-5">
          <h2 className="w-full text-start font-bold text-[16px] xs:text-[20px]">Tags</h2>
          <div className="flex flex-wrap w-full h-full gap-4">
            {TagData.map((item, index) => (
              <div key={index} onClick={() => selectTag(item)} className={`${tags.includes(item)
                ? "bg-blue-700 text-white"
                : ""
                } flex flex-col px-3 py-3 text-[16px] font-medium border-[0.5px] border-color-400 rounded-lg cursor-pointer`}>{item}</div>
            ))}

          </div>
        </div>
        <div className="w-full h-full flex flex-col items-start gap-3">
          <div className="w-full text-start font-bold text-[20px] xs:text-[24px]">Schedule</div>
          <label className="w-[280px] inline-flex justify-start items-center cursor-pointer">
            <input onClick={() => setSchedule(!schedule)} type="checkbox" value="" className="sr-only peer" />
            <div className="relative w-[60px] h-8 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
            <div className="flex flex-row items-center ms-3 text-lg font-medium text-gray-900 dark:text-gray-300 gap-2">TBA<span className="font-light text-[16px]">(To Be Announced)</span></div>
          </label>
          <div className="w-full h-full flex flex-col xs:flex-row justify-center xs:justify-between items-center gap-5">
            <div className="w-full h-full flex flex-col text-start gap-2">
              <div className="font-bold text-[12px] xs:text-[16px]">Start Date</div>
              <input disabled={schedule} type="date" onChange={(e) => changeTitle(e.target.value, "date")} className={`${schedule ? "text-color-100" : ""} flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold`} value={person.date} />
            </div>
            <div className="w-full h-full flex flex-col text-start gap-2">
              <div className="font-bold text-[12px] xs:text-[16px]">Start Time</div>
              <input disabled={schedule} type="time" onChange={(e) => changeTitle(e.target.value, "time")} className={`${schedule ? "text-color-100" : ""} flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold`} value={person.time} />
            </div>
          </div>
        </div>
        <div className="w-full h-full flex flex-col items-center gap-3">
          <div className="w-full text-start font-bold text-[20px] xs:text-[24px]">Detail</div>
          <div className="w-full h-full flex flex-col xs:flex-row justify-center xs:justify-between items-center gap-5">
            <div className="w-full h-full flex flex-col text-start gap-2">
              <div className="font-bold text-[16px] xs:text-[20px]">Token Supply</div>
              <input type="number" onChange={(e) => changeTitle(e.target.value, "tokenNmber")} className="flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold" value={person.tokenNmber} placeholder="10000" />
            </div>

            <div className="w-full h-full flex flex-col text-start gap-2">
              <div className="font-bold text-[16px] xs:text-[20px]">Blockchain</div>
              <div onClick={() => setDropdown(true)} className="flex flex-col w-full py-3 rounded-xl bg-color-500 outline-none font-semibold relative" >
                <div className="px-3 flex flex-row justify-between items-center">
                  {chain}
                  {dropdown ? <FaAngleUp /> : <FaAngleDown />}
                </div>
                <div ref={menuDropdown} className={`${dropdown ? "h-52 py-3 border-[1px] border-color-400" : "h-0"} flex flex-col w-full rounded-md bg-color-500  object-cover overflow-hidden overflow-y-scroll font-semibold absolute mt-11 duration-300`}>
                  {SelectValue.map((item, index) => (
                    <div onClick={() => changeChain(item.chain)} key={index} className="flex flex-col w-full cursor-pointer py-2 px-3 hover:bg-color-300 z-10">
                      {item.chain}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-full flex flex-col xs:flex-row justify-center xs:justify-between items-center gap-5">
            <div className="w-full h-full flex flex-col text-start gap-2">
              <div className="font-bold text-[16px] xs:text-[20px]">Pre-Mint Price</div>
              <input type="number" onChange={(e) => changeTitle(e.target.value, "preMintPrice")} className="flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold" value={person.preMintPrice} placeholder="0.03" />
            </div>

            <div className="w-full h-full flex flex-col text-start gap-2">
              <div className="font-bold text-[16px] xs:text-[20px]">Public Mint Price</div>
              <input type="number" onChange={(e) => changeTitle(e.target.value, "pubMintPrice")} className="flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold" value={person.pubMintPrice} placeholder="0.05" />
            </div>
          </div>
          <div className="w-full h-full flex flex-col text-start gap-2">
            <div className="font-bold text-[16px] xs:text-[20px]">Mint Limit</div>
            <input type="number" onChange={(e) => changeTitle(e.target.value, "mintLimit")} className="flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold" value={person.mintLimit} placeholder="5" />
          </div>
          <div className="w-full h-full flex flex-col text-start gap-2">
            <div className="font-bold text-[16px] xs:text-[20px]">Mint URL</div>
            <input onChange={(e) => changeURL(e.target.value, "mint")} className="flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold" value={personURL.mint} placeholder="https://" />
          </div>
          <div className="w-full h-full flex flex-col text-start gap-2">
            <div className="font-bold text-[16px] xs:text-[20px]">Scan URL</div>
            <input onChange={(e) => changeURL(e.target.value, "scan")} className="flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold" value={personURL.scan} placeholder="https://" />
          </div>
        </div>
        <div className="w-full h-full flex flex-col gap-3">
          <div className="w-full text-start font-bold text-[20px] xs:text-[24px]">Detail</div>
          <div className="w-full text-start font-bold text-[16px] xs:text-[20px]">Gallery</div>
          <ImageUploading
            value={gallery}
            onChange={onChangeGallery}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper relative w-[120px]">
                <button
                  style={isDragging ? { color: 'red' } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <div className="w-[120px] h-[120px] rounded-lg flex flex-col justify-center items-center bg-color-100 text-color-200 text-5xl">
                    <IoIosAddCircle />
                  </div>
                </button>
                &nbsp;
                <button className="flex flex-col text-xl absolute top-1 text-white left-[95px] z-20" onClick={onImageRemoveAll}>
                  <MdDelete />
                </button>
                {imageList.map((item, index) => (
                  <div key={index} className="image-item absolute z-10 top-0">
                    <img src={item['data_url']} alt="input" className="w-[120px] h-[120px] rounded-lg" type="image/webp" />
                    <div className="image-item__btn-wrapper">
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </div>
        <div className="w-full h-full flex flex-col justify-start items-center gap-3">
          <div className="w-full text-start font-bold text-[20px] xs:text-[24px]">Social</div>
          <div className="w-full text-start font-bold text-[16px] xs:text-[20px]">Web Site</div>
          <input onChange={(e) => changeURL(e.target.value, "webSite")} className="flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold" value={personURL.website} placeholder="https://" />
          <div className="w-full text-start font-bold text-[16px] xs:text-[20px]">Discord</div>
          <input onChange={(e) => changeURL(e.target.value, "discord")} className="flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold" value={personURL.discord} placeholder="https://" />
          <div className="w-full text-start font-bold text-[16px] xs:text-[20px]">Telegram</div>
          <input onChange={(e) => changeURL(e.target.value, "telegram")} className="flex flex-col w-full px-4 py-3 rounded-xl bg-color-500 outline-none font-semibold" value={personURL.telegram} placeholder="https://" />
        </div>
        <div className="w-full flex flex-row justify-center items-center gap-10 py-10">
          <button onClick={() => submitData()} className="w-32 flex flex-col items-center text-center rounded-lg bg-[#e87169] hover:bg-[#e55c53] py-2 cursor-pointer">
            <span className="text-white text-xl font-bold">Submit</span>
          </button>
          <button onClick={() => goToPage("/")} className="w-32 flex flex-col items-center text-center rounded-lg bg-[#686ada] hover:bg-[#3e48db] py-2 cursor-pointer">
            <span className="text-white text-xl font-bold">Cancel</span>
          </button>
        </div>
      </div>
      {loading ?
        <Loading /> : < ></>
      }
    </div >
  )
}