import React from "react";
import { FaTelegram, FaDiscord } from "react-icons/fa";
import { TfiWorld } from "react-icons/tfi";
import { BsTwitterX } from "react-icons/bs";

export default function Footer() {
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Niftydrops",
    "url": "https://www.nftdrops.io/",
    "logo": "https://www.nftdrops.io/logo192.png",
    "sameAs": [
      "https://t.me/niftydrops",
      "https://discord.com/invite/8mrCNZzw4k",
      "https://x.com/niftydrops"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "Customer Support",
      "email": "support@niftydrops.io"
    }
  };

  return (
    <div className="w-full h-32 3xs:h-28 flex flex-col justify-center items-center bg-black text-white gap-2 pt-5">
      {/* JSON-LD Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(jsonLdData)}
      </script>

      {/* Social Icons */}
      <div className="w-full max-w-[1440px] flex flex-row justify-center gap-3 text-xl sm2:text-3xl px-4">
        <a href="https://www.nftdrops.io/" className="cursor-pointer" aria-label="Visit NFT Drops">
          <TfiWorld />
        </a>
        <a href="https://t.me/niftydrops" className="cursor-pointer" aria-label="Join Telegram">
          <FaTelegram />
        </a>
        <a href="https://discord.com/invite/8mrCNZzw4k" className="cursor-pointer" aria-label="Join Discord">
          <FaDiscord />
        </a>
        <a href="https://x.com/niftydrops" className="cursor-pointer" aria-label="Follow on X">
          <BsTwitterX />
        </a>
      </div>

      {/* Footer Text and Links */}
      <div className="w-full max-w-[1440px] flex flex-col sm:flex-row justify-between items-center text-sm md:text-base px-4 xs:px-14 mt-2">
        <div className="flex justify-center items-center mb-2 sm:mb-0">
          © 2024 Niftydrops All rights reserved.
        </div>
        <div className="flex justify-center sm:justify-end gap-4">
          <a href="/" className="px-3 py-1 lg:py-2 border-b-2 border-b-black hover:border-b-color-100">
            Privacy Policy
          </a>
          <a href="/" className="px-3 py-1 lg:py-2 border-b-2 border-b-black hover:border-b-color-100">
            Terms & Conditions
          </a>
        </div>
      </div>
    </div>
  );
}
