import BSC from "../assets/token images/BSC.png"
import Solana from "../assets/token images/Solana.png"
import ETH from "../assets/token images/ETH.png"
import Tron from "../assets/token images/Tron.png"
import Polygon from "../assets/token images/Polygon.png"
import Wax from "../assets/token images/Wax.png"
import Waves from "../assets/token images/Waves.png"
import Avalanche from "../assets/token images/Avalanche.png"
import Other from "../assets/token images/Other.png"
import Flow from "../assets/token images/Flow.png"


export const TagData = [
    "NFT Drop",
    "Play2Earn",
    "Event",
    "Airdrop",
    "ICO",
    "Staking",
    "Art",
    "Game",
    "Collection",
    "Token",
    "Community",
    "DEFi",
    "Access",
    "DAO",
    "Membership",
]

export const SelectValue = [
    { chain: "ETH", id: 1 },
    { chain: "Polygon", id: 2 },
    { chain: "Flow", id: 3 },
    { chain: "WAX", id: 4 },
    { chain: "Solana", id: 5 },
    { chain: "BSC", id: 6 },
    { chain: "TRON", id: 7 },
    { chain: "Waves", id: 8 },
    { chain: "Avalanche", id: 9 },
    { chain: "other", id: 10 },
]

export const ChainIcon = [
    { chain: "ETH", url: ETH },
    { chain: "Polygon", url: Polygon },
    { chain: "Flow", url: Flow },
    { chain: "WAX", url: Wax },
    { chain: "Solana", url: Solana },
    { chain: "BSC", url: BSC },
    { chain: "TRON", url: Tron },
    { chain: "Waves", url: Waves },
    { chain: "Avalanche", url: Avalanche },
    { chain: "Other", url: Other },
]

export const SEOKeyWords = [
    "NFT drops, Upcoming NFT drops, New NFT drops, NFT mint schedule, NFT release dates, NFT launch, NFT drop calendar, NFT drop schedule, NFT minting, Best NFT drops, Top NFT drops this week, NFT drops today, NFT drops July 2024, Upcoming NFT minting events, New NFT collections, NFT drop alerts, NFT presale, NFT minting guide, NFT drop news, OpenSea NFT drops, Rarible NFT drops, Binance NFT drops, Solana NFT drops, Ethereum NFT drops, What are the best upcoming NFT drops, How to find new NFT drops, Best NFT projects launching soon, Upcoming NFT art drops, How to participate in NFT drops, Upcoming NFT games, Latest NFT drops on OpenSea, Where to find NFT drops, Upcoming NFT drops July 2024, New NFT mint schedule for July, Top NFT releases this week, Best NFT drops on Ethereum, Solana NFT minting events, nft, nifty, drop, drops, niftydrops, NFT drops, tips, nfts, days, nfydrops, blogs, submit, upcoming, Submit, Blogs, project, time, portfolio"
]