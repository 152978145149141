import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GrLinkNext } from "react-icons/gr";
import UseContext from "../../context/useContext";

export default function Blog({ blogsdata }) {
  const { data } = useContext(UseContext);
  const { viewState, setViewState } = data;
  const navigate = useNavigate();

  const goToPage = (url) => {
    navigate(url);
  }

  return (
    <div className="w-full max-w-[1440px] flex flex-col justify-center items-center bg-white mx-auto my-10 gap-6 px-4 xs:px-14">
      <div className="flex flex-row w-full justify-between items-center font-semibold text-[20px]">
        <h1 className="text-color-100">Blogs</h1>
        <h2 onClick={() => setViewState(!viewState)} className="flex flex-row gap-1 items-center text-color-100 cursor-pointer">
          View all posts <GrLinkNext className="text-[12px] mt-1" />
        </h2>
      </div>
      <div className="w-full h-full flex flex-wrap items-start justify-center gap-x-4 gap-y-8 md:gap-x-9 md:gap-y-16">
        {blogsdata.map((item, index) => (
          <div key={index} onClick={() => goToPage(`/blog/${item.slug}`)} className="max-w-[400px] h-full flex flex-col items-center justify-between rounded-md cursor-pointer">
            <img
              srcSet={`${item.imageUrl}?w=400 400w, ${item.imageUrl}?w=800 800w, ${item.imageUrl}?w=1200 1200w`}
              sizes="(max-width: 400px) 100vw, (max-width: 800px) 50vw, 25vw"
              src={`${item.imageUrl}?w=800`}
              alt="blog"
              width="800"
              height="450"
              className="rounded-md w-full object-cover aspect-video"
            />
            <div className="w-full flex flex-col py-4">
              <span className={`flex flex-col py-1 px-3 font-bold rounded-lg text-white text-[14px] 3xs:text-[18px] text-center`} style={item.topicStyle}>{item.topic}</span>
              <span className="w-full text-start py-1 font-bold text-black h-7 overflow-hidden text-[14px] 3xs:text-[18px]">{item.title}</span>
              <div className="flex flex-col text-start mt-3 gap-6 text-[12px] 3xs:text-[16px]">
                <span className="font-semibold max-h-10 3xs:max-h-12 overflow-hidden text-black">{item.shortDescription}</span>
                <span className="font-normal text-color-100">{item.date}</span>
              </div>
            </div>
          </div>

        ))}
      </div>
    </div>
  );
}
