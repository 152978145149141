import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UseContext from "../../context/useContext";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Loading from "../loding/Loding";
import axios from 'axios';
import { BACKEND_URL } from "../../config/config";
import DOMPurify from 'dompurify';
import SEO from "../seo";
import { SEOKeyWords } from "../../context/AuthData";

export default function BlogData() {
  const { id } = useParams();
  const { data } = useContext(UseContext);
  const { loading, setLoading } = data
  const [blogData, setBlogData] = useState({})
  const navigate = useNavigate();

  const goToPage = (url) => {
    navigate(url);
  }

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const blog = await axios.get(`${BACKEND_URL}/api/blogs/`, { params: { id: id } })
      setBlogData(blog.data[0])
      setLoading(false);
    }
    fetchData()
  }, [id])

  return (
    <div className="w-full max-w-[1440px] min-h-[calc(100vh-192px)] flex flex-col bg-white mx-auto pt-6 pb-12 xs:pt-12 px-4 xs:px-14" >
      <SEO
        title={`${blogData.title}| Nifty Drops | Back to Blog`}
        description={`${blogData.title}`}
        keywords={SEOKeyWords}
      />
      <div className="w-full h-full flex flex-col gap-5">
        <h1 onClick={() => goToPage("/")} className="flex flex-row w-full text-start text-[#e87169] text-[16px] 3xs:text-[20px] font-semibold cursor-pointer items-center gap-1">
          <AiOutlineArrowLeft />
          Back to Blog
        </h1>
        <div className="w-full h-full flex flex-col gap-5">
          <div className="w-full h-full flex flex-col gap-1">
            <span className="w-full text-start font-bold text-black text-[20px] 3xs:text-[28px]">{blogData.title}</span>
            <span className="w-full text-start font-normal text-color-100 text-[14px] 3xs:text-[20px]">{blogData.date}</span>
          </div>
          <img
            src={blogData.imageUrl}
            srcSet={`${blogData.imageUrl} 320w, ${blogData.imageUrl} 768w, ${blogData.imageUrl} 1024w`}
            sizes="(max-width: 320px) 280px, (max-width: 768px) 680px, (max-width: 1024px) 980px, 1200px"
            type="image/webp"
            alt="blog"
            width="1200"
            height="800"
            className="rounded-md w-full h-[180px] 3xs:h-[200px] 2xs:h-[230px] xs:h-[250px] sm:h-[280px] sm2:h-[300px] md:h-[320px] md2:h-[340px] lg:h-[360px] lg2:h-[380px] xl:h-[400px] object-cover aspect-video"
          />
          <div className="w-full h-full text-start justify-start flex flex-col text-[16px] 3xs:text-[20px] pt-5" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blogData.description) }}>
          </div>
        </div>
      </div>
      {loading ?
        <Loading /> : < ></>
      }
    </div >
  )
}