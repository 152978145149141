import React from "react";
import Slider from "react-slick";
import { FaTelegram, FaDiscord } from "react-icons/fa";
import { TfiWorld } from "react-icons/tfi";
import CountdownTimer from "./CountDown";

export default function NFTSlider({ data }) {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    pauseOnHover: true
  };

  return (
    <div className="w-full max-w-[1440px] px-4 xs:px-14">
      <div className="slider-container">
        <Slider {...settings}>
          {data?.map((item, index) => (
            index < 3 &&
            <div key={index} className={`relative max-w-[1320px] h-[180px] 3xs:h-[200px] 2xs:h-[230px] xs:h-[250px] sm:h-[280px] sm2:h-[300px] md:h-[320px] md2:h-[340px] lg:h-[360px] lg2:h-[380px] xl:h-[400px] flex flex-col overflow-hidden rounded-xl object-cover gap-2`}>
              <img
                src={item.imageUrl}
                srcSet={`
                 ${item.imageUrl}?w=480 480w,
                 ${item.imageUrl}?w=768 768w,
                 ${item.imageUrl}?w=1024 1024w,
                 ${item.imageUrl}?w=1440 1440w
                 `}
                sizes="(max-width: 480px) 100vw,
               (max-width: 768px) 100vw,
               (max-width: 1024px) 100vw,
               100vw"
                type="image/webp"
                alt="project"
                width="1440"
                height="810"
                className="w-full h-full object-cover border-2 border-color-300 rounded-xl aspect-video"
              />
              <div key={index} className={`absolute w-full flex flex-col  rounded-xl  gap-2 z-10 bottom-6 px-4 xs:px-11`}>
                <div className="w-full flex flex-row items-center justify-start gap-1 xs:gap-3 ">
                  <img src={item.gallary} alt="Avatar" className="w-12 h-12 xs:w-16 xs:h-16 rounded-md aspect-all" />
                  <div className="flex flex-col items-center text-start text-white">
                    <div className="flex flex-col text-md xs:text-2xl font-extrabold w-[150px] xs:w-full">
                      {item.title}
                    </div>
                    <div className="flex flex-col text-sm xs:text-lg font-light w-[150px] xs:w-full h-[20px] xs:h-[28px] object-cover overflow-hidden break-words ">
                      {item.description}
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-row justify-between items-center">
                  <CountdownTimer targetDate={item.startData + "T" + item.startTime} />
                  <div className="flex flex-row gap-1 xs:gap-4 text-white items-center">
                    <a href={item.telegram} className="cursor-pointer">
                      <FaTelegram className={`${item.telegram === undefined || item.telegram === "" ? "hidden" : ""} w-5 h-5 xs:w-6 xs:h-6 lg:w-8 lg:h-8`} />
                    </a>
                    <a href={item.discord} className="cursor-pointer">
                      <FaDiscord className={`${item.discord === undefined || item.discord === "" ? "hidden" : ""} w-5 h-5 xs:w-6 xs:h-6 lg:w-8 lg:h-8`} />
                    </a>
                    <a href={item.website} className="cursor-pointer">
                      <TfiWorld className={`${item.website === undefined || item.website === "" ? "hidden" : ""} w-5 h-5 xs:w-6 xs:h-6 lg:w-8 lg:h-8`} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
