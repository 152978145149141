import './App.css';
import axios from 'axios';
import React, { useState, useMemo } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UseContext from './context/useContext';
import Header from './component/header/Header';
import Footer from './component/footer/Footer';
import Navbar from './component/navbar/Navbar';
import Submit from './component/submit/Submit';
import BlogData from './component/blog/BlogData';
import ErrorPage from "./component/error/index";
import { BACKEND_URL } from './config/config';
import SEO from './component/seo';
import { SEOKeyWords } from './context/AuthData';

function App() {
  const [personURL, setPersonURL] = useState({});
  const [person, setPerson] = useState({});
  const [images, setImages] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [tags, setTags] = useState([]);
  const [chain, setChain] = useState("Other");
  const [schedule, setSchedule] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [listState, setListState] = useState(true)
  const [loading, setLoading] = useState(false);
  const [viewState, setViewState] = useState(false);

  const data = useMemo(
    () => ({
      personURL,
      setPersonURL,
      person,
      setPerson,
      images,
      setImages,
      gallery,
      setGallery,
      tags,
      setTags,
      schedule,
      setSchedule,
      dropdown,
      setDropdown,
      chain,
      setChain,
      loading,
      setLoading,
      listState,
      setListState,
      viewState,
      setViewState,
    }),
    [
      personURL,
      person,
      images,
      gallery,
      tags,
      schedule,
      dropdown,
      chain,
      loading,
      listState,
      viewState,
    ]
  )
  const sumbitDrop = async (data) => {
    const response = await axios.post(`${BACKEND_URL}/api/drops/`, data);

  }

  const sumbitBrog = async (brogData) => {
    const response = await axios.post(`${BACKEND_URL}/api/blogs/`, brogData);

  }

  return (
    <div className="App min-h-screen">
      <SEO
        title="Upcoming nfts Drops Project Submit tips time portfolio test"
        description="NFTDrops is the first release and event calendar for the growing nft, nfts industry. Find the hottest upcoming NFT Drops on NFT Drops. The best NFT Calendar and App. Never miss an NFT Drop again. List your NFT Project. Submit and nfts Blogs General, tips time, portfolio test"
        keywords={SEOKeyWords}
      />
      <UseContext.Provider value={{ data, sumbitDrop, sumbitBrog }}>
        <Router >
          <Header />
          <Routes>
            <Route exact path="/" element={<Navbar />} />
            <Route exact path="/submit" element={<Submit />} />
            <Route exact path="/blog/:id" element={<BlogData />} />
            <Route exact path="*" element={<ErrorPage />} />
          </Routes>
          <Footer />
        </Router>
      </UseContext.Provider>
    </div>
  );
}

export default App;
