import React from "react";
import { useNavigate } from "react-router-dom";
import LogoImg from "../../assets/img/logo.png"

export default function Header() {
  const navigate = useNavigate();

  const goToPage = (url) => {
    navigate(url);
  }

  return (
    <header className="w-full h-24 flex flex-col justify-center items-center bg-white z-10 border-b-[1px] border-b-black">
      <div className="w-full h-20 max-w-[1440px] flex justify-between items-center px-4 xs:px-14">
        <img onClick={() => goToPage("/")} type="image/webp"  src={LogoImg} alt="LogoImg" className="w-14 h-14 cursor-pointer" />
        <button onClick={() => goToPage("/submit")} className="w-32 flex flex-col items-center text-center rounded-lg bg-[#e87169] hover:bg-[#e55c53] py-2 cursor-pointer">
          <h2 className="text-white text-xl font-bold">Submit</h2>
        </button>
      </div>
    </header>
  );
}
