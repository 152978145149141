import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer); // Cleanup timer on component unmount
    }, [targetDate]);

    return (
        <div>
            {timeLeft.days !== undefined ? (
                <span className="flex text-white items-center font-semibold gap-1 xs:gap-2">
                    <span className="flex flex-col w-8 h-10 xs:w-16 xs:h-14 bg-slate-200 justify-center items-center rounded-md">
                        <div className="text-[16px] xs:text-[24px] text-color-400">{timeLeft.days}</div>
                        <div className="text-[8px] xs:text-[12px] text-color-100">days</div>
                    </span>
                    <span className="flex flex-col w-8 h-10 xs:w-16 xs:h-14 bg-slate-200 justify-center rounded-md">
                        <span className="text-[16px] xs:text-[24px] text-color-400">{timeLeft.hours}</span>
                        <span className="text-[8px] xs:text-[12px] text-color-100">hrs</span>
                    </span>
                    <span className="flex flex-col w-8 h-10 xs:w-16 xs:h-14 bg-slate-200 justify-center rounded-md">
                        <span className="text-[16px] xs:text-[24px] text-color-400">{timeLeft.minutes}</span>
                        <span className="text-[8px] xs:text-[12px] text-color-100">min</span>
                    </span>
                    <span className="flex flex-col w-8 h-10 xs:w-16 xs:h-14 bg-slate-200 justify-center rounded-md">
                        <span className="text-[16px] xs:text-[24px] text-color-400">{timeLeft.seconds}</span>
                        <span className="text-[8px] xs:text-[12px] text-color-100">sec</span>
                    </span>
                </span>
            ) : (
                <span className="flex flex-col w-full px-4 py-2 bg-slate-200 justify-center rounded-md text-[16px] xs:text-[24px]">
                    Time's up!
                </span>
            )
            }
        </div >
    );
};

export default CountdownTimer;
