import React from "react";
import Slider from "react-slick";
import { FaTelegram, FaDiscord } from "react-icons/fa";
import { TfiWorld } from "react-icons/tfi";

function ImageSlider({ data }) {
  var settings = {
    dots: true,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    autoplaySpeed: 1500,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };


  return (
    <div className="w-full max-w-[1440px] mt-10 px-1 xs:px-10">
      <div className="slider-container">
        <Slider {...settings}>
          {data.map((item, index) => (
            index < 6 &&
            <div key={index} className="p-4 rounded-xl">
              <img
                src={item.imageUrl}
                srcSet={`${item.imageUrl}?w=300 300w,
                 ${item.imageUrl}?w=600 600w,
                 ${item.imageUrl}?w=900 900w`}
                sizes="(max-width: 640px) 300px,
               (max-width: 768px) 600px,
               900px"
                type="image/webp"
                alt="project"
                width="900"
                height="675"
                className="rounded-xl object-cover w-full h-full aspect-video"
              />
              <div className="flex flex-row justify-between w-full px-4 items-center -mt-6 xs:-mt-12">
                <div className="flex flex-col text-md xs:text-2xl text-start text-white font-extrabold w-full xs:w-[300px]">
                  {item.title}
                </div>
                <div className="flex flex-row gap-1 xs:gap-4 text-white justify-center items-center xs:py-3">
                  <a href={item.telegram} className="cursor-pointer">
                    <FaTelegram className={`${item.telegram === undefined || item.telegram === "" ? "hidden" : ""} w-4 h-4 xs:w-6 xs:h-6`} />
                  </a>
                  <a href={item.discord} className="cursor-pointer">
                    <FaDiscord className={`${item.discord === undefined || item.discord === "" ? "hidden" : ""} w-4 h-4 xs:w-6 xs:h-6`} />
                  </a>
                  <a href={item.website} className="cursor-pointer">
                    <TfiWorld className={`${item.website === undefined || item.website === "" ? "hidden" : ""} w-4 h-4 xs:w-6 xs:h-6`} />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default ImageSlider;
