import React, { useContext, useCallback } from "react";
import { FaTelegram, FaDiscord } from "react-icons/fa";
import { TfiWorld } from "react-icons/tfi";
import UseContext from "../../context/useContext";
import { ChainIcon } from "../../context/AuthData";

export default function ActiveNFTList({ activeData }) {
  const { data } = useContext(UseContext);
  const { listState, setListState } = data;
  const changeListState = useCallback((state) => {
    setListState(state);
  }, []);

  const containerClasses = "min-w-[280px] w-full max-w-[300px] h-full flex flex-col items-center justify-between rounded-lg border-[1px] border-color-100";

  return (
    <div className="w-full max-w-[1440px] flex flex-col justify-start items-center bg-white gap-8 px-4 xs:px-14 my-10">
      <div className="flex flex-row w-full text-start text-[12px] 3xs:text-[16px] font-semibold text-color-100">
        <span
          onClick={() => changeListState(true)}
          className={`${listState ? "rounded-l-lg bg-color-200/30" : ""} flex flex-row px-4 py-3 3xs:px-8 3xs:py-5 cursor-pointer`}
        >
          Active & Upcoming
        </span>
        <span
          onClick={() => changeListState(false)}
          className={`${!listState ? "rounded-r-lg bg-color-200/30" : ""} flex flex-row px-4 py-3 3xs:px-8 3xs:py-5 cursor-pointer`}
        >
          Past
        </span>
      </div>
      <div className="w-full h-full flex flex-wrap items-center justify-center gap-x-4 gap-y-8 md:gap-x-9 md:gap-y-16">
        {activeData?.map((item, index) => (
          <div key={index} className={containerClasses}>
            <div className="flex flex-col w-full h-[200px]">
              <img
                srcSet={`${item.imageUrl}?w=200&h=200 1x, ${item.imageUrl}?w=400&h=400 2x`}
                src={`${item.imageUrl}?w=200&h=200`}
                type="image/webp"
                alt="NFT list file"
                width="200"
                height="200"
                className="rounded-t-lg w-full h-full object-cover aspect-all"
              />
              <div className="flex flex-row gap-1 justify-end xs:gap-3 text-white items-center -mt-7 px-3">
                <a href={item.telegram} className="cursor-pointer">
                  <FaTelegram className={`${item.telegram === undefined || item.telegram === "" ? "hidden" : ""} w-5 h-5 xs:w-6 xs:h-6`} />
                </a>
                <a href={item.discord} className="cursor-pointer">
                  <FaDiscord className={`${item.discord === undefined || item.discord === "" ? "hidden" : ""} w-5 h-5 xs:w-6 xs:h-6`} />
                </a>
                <a href={item.website} className="cursor-pointer">
                  <TfiWorld className={`${item.website === undefined || item.website === "" ? "hidden" : ""} w-5 h-5 xs:w-6 xs:h-6`} />
                </a>
              </div>
            </div>
            <div className="flex flex-col w-full px-2">
              <div className="max-w-[300px] flex flex-col px-1 py-2 truncate text-[18px] font-bold">
                {item.title}
              </div>
              <div className="w-full flex flex-row justify-between items-center px-1 py-2">
                <div className="flex flex-col gap-1 text-[14px] text-color-100">
                  <span className="font-light">Minting</span>
                  <span className="font-semibold">{item.startData === "" ? "Past" : item.startData}</span>
                </div>
                <div className="flex flex-row gap-2 items-center">
                  {ChainIcon.map((icon, index) => (
                    icon.chain === item.blockchain &&
                    <img key={index} src={icon.url} alt={icon.chain} className="w-8 h-8 rounded-full" />
                  ))}
                  <div className="flex flex-col justify-start gap-1 text-[14px] text-color-100">
                    <span className="font-light">Price</span>
                    <span className="font-semibold">{item.preMintPrice} {item.blockchain}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
