import React from 'react'
import Logo from "../../assets/img/logo.png"

export default function Loading() {
    return (
        <div className='w-full h-screen fixed top-0 left-0 bg-black/90 items-center justify-center flex flex-col z-40'>
            <div className='w-[200px] bg-color-500 rotate-45 rounded-xl font-sigmar relative'>
                <div className='w-full h-full pt-14 pb-14'>
                    <div
                        className='inline-block h-20 w-20 animate-spin text-[#E2E2E2] rounded-full border-4 border-solid border-current border-r-[#FF4C8C] align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]'
                        role='status'
                    >
                        <span className='!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]'>
                            Loading...
                        </span>
                    </div>
                </div>
                <img src={Logo} alt='Logo' className='absolute  -rotate-45 top-[75px] left-[77px] flex flex-col z-20 w-12 h-12' />

            </div>
        </div>
    )
}
