import React, { useEffect, useState, useContext } from "react";
import Slider from "./NftSlider";
import ImageSlider from "./ImageSlider";
import ActiveNFTList from "./ActiveNftList";
import Blog from "./Blog";
import axios from 'axios';
import UseContext from "../../context/useContext";
import Loading from "../loding/Loding";
import { BACKEND_URL } from "../../config/config";
import SEO from "../seo";
import { SEOKeyWords } from "../../context/AuthData";

export default function Navbar() {
  const { data } = useContext(UseContext);
  const { listState, loading, setLoading } = data;
  const [nftDropData, setNftDropData] = useState([]);
  const [activeDropData, setActiveDropData] = useState([]);
  const [pastDropData, setPastDropData] = useState([]);
  const [blogData, setBlogData] = useState([]);

  const dropSort = (data) => {
    const nftData = [];
    const activeData = [];
    const pastData = [];

    if (data.length > 0) {
      const shuffled = [...data].sort(() => 0.5 - Math.random());
      const selected = data.length === 1 ? [data[0]] : shuffled.slice(0, Math.min(3, data.length));
      setNftDropData(selected);
    }

    for (let i = 0; i < data.length; i++) {
      const difference = +new Date(data[i].startData + "T" + data[i].startTime) - +new Date();
      if (difference > 0) {
        activeData.push(data[i]);
      } else {
        pastData.push(data[i]);
      }
    }
    // Convert date strings to Date objects and sort them
    const activeDropData = activeData
      .sort((a, b) => new Date(a.startData + "T" + a.startTime) - new Date(b.startData + "T" + b.startTime));
    const pastDropData = pastData
      .sort((a, b) => new Date(a.startData + "T" + a.startTime) - new Date(b.startData + "T" + b.startTime));
    // After the loop, update the state
    setActiveDropData(activeDropData);
    setPastDropData(pastDropData);
  };

  const blogSort = (data) => {
    // Convert date strings to Date objects and sort them
    const blogSorted = data
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    setBlogData(blogSorted);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await axios.get(`${BACKEND_URL}/api/drops/`)
      const blogsData = await axios.get(`${BACKEND_URL}/api/blogs/`)
      dropSort(data.data)
      blogSort(blogsData.data)
    }
    fetchData()
  }, [])

  return (
    <div className="w-full max-w-[1440px] min-h-[calc(100vh-192px)] flex flex-col justify-center items-center bg-white mx-auto pt-6 xs:pt-12" >
      <SEO
        title="Upcoming nfts Drops Project Submit tips time portfolio test"
        description="NFTDrops is the first release and event calendar for the growing nft, nfts industry. Find the hottest upcoming NFT Drops on NFT Drops. The best NFT Calendar and App. Never miss an NFT Drop again. List your NFT Project. Submit and nfts Blogs General, tips time, portfolio, test"
        keywords={SEOKeyWords}
      />
      <Slider data={nftDropData} />
      <ImageSlider data={activeDropData} />
      <ActiveNFTList activeData={listState ? activeDropData : pastDropData} />
      <Blog blogsdata={blogData} />
      {loading ?
        <Loading /> : < ></>
      }
    </div >
  )
}